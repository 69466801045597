import $ from "jquery";

export default class Module {
    constructor($scope) {
        this.$scope = $scope;
    }

    $(selector) {
        return $(selector, this.$scope);
    }

    start() {
    }

    stop() {
    }
}