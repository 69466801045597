// necessary so the vendor chunk is executed
require("vendor");

import $ from "jquery";
import app from "./app/Application";

// modules
import Code from "module/Code";
import Menu from "module/Menu";
import AAjax from "module/AAjax";
import Toast from "module/Toast";
import WYSIWYG from "module/WYSIWYG";

$(function() {
    app.start();
});