import app from "app/Application";
import Module from "module/Module";
import hljs from "highlight.js";

export default class Code extends Module {
    start() {
        this.$(".js-code").each(function () {
            hljs.highlightBlock(this);
        });
    }
}

app.registerModule("Code", Code);