import $ from "jquery";

class Application {
    constructor() {
        this.modules = {};
        this.activeModules = {};
    }

    registerModule(name, module, isSingleton) {
        this.modules[name] = {
            module: module,
            isSingleton: isSingleton
        };
    }

    getConfig(key) {
        if (!key) {
            return this.config.config;
        }

        return this.config.config[key];
    }

    translate(key) {
        return this.config.translations[key] || key;
    }

    start() {
        this.config = $.parseJSON($("#js-config").html());

        this.startModules($("body"), this.config.modules);
    }

    startModules($scope, modules) {
        $.each(modules, (index, moduleName) => {
            if (!this.modules[moduleName]) {
                return false;
            }

            try {
                const moduleDefinition = this.modules[moduleName];

                if (moduleDefinition.isSingleton) {
                    if (this.activeModules[moduleName]) {
                        return true;
                    }

                    const module = new moduleDefinition.module($scope);
                    this.activeModules[moduleName] = module;

                    module.start();
                } else {
                    let activeModules = this.activeModules[moduleName];

                    if (activeModules === undefined) {
                        activeModules = [];
                        this.activeModules[moduleName] = activeModules;
                    }

                    const module = new moduleDefinition.module($scope);
                    activeModules.push(module);

                    module.start();
                }
            } catch(exception) {
                console.error("Could not initialize module " + moduleName);
                console.error(exception);
            }
        });
    }
}

export default new Application();