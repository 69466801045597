import app from "app/Application";
import Module from "module/Module";

export default class WYSIWYG extends Module {
    start() {
        this.$(".js-wysiwyg").trumbowyg({
            btns: [
                ["undo", "redo"],
                ["strong", "em"],
                ["unorderedList", "orderedList"],
                ["link"]
            ]
        });
    }
}

app.registerModule("WYSIWYG", WYSIWYG);