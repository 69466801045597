import app from "app/Application";
import Module from "module/Module";
import Toast from "module/Toast";

export default class AAjax extends Module {
    start() {
        this.$(".js-aajax").on("click", function (event) {
            event.preventDefault();

            const $this = $(this);
            const href = $this.data("href");

            $.ajax($this.attr("href"))
                .then(() => {
                    if (href) {
                        window.location.href = href;
                    }
                })
                .catch(() => {
                    Toast.error(app.translate("Ups"), app.translate("Something went wrong"), "error");
                })
        });
    }
}

app.registerModule("AAjax", AAjax);