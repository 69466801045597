import app from "app/Application";
import Module from "module/Module";
import $ from "jquery";

export default class Menu extends Module {
    constructor($scope) {
        super($scope);

        this.$body = $("body");
        this.$toggle = this.$(".js-menu-toggle");
        this.$backdrop = this.$(".js-menu-backdrop");
    }

    start() {
        this.$toggle.on("click", () => {
            this.$body.toggleClass("menu-open");
        });

        this.$backdrop.on("click", () => {
            this.$body.removeClass("menu-open");
        });
    }
}

app.registerModule("Menu", Menu, true);