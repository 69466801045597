import app from "app/Application";
import Module from "module/Module";
import $ from "jquery";
import iziToast from "izitoast";

export default class Toast extends Module {
    start() {
        const toasts = app.getConfig("toasts");

        $.each(toasts, (i, toast) => {
            Toast.display(toast);
        });

        this.$(".js-toast").on("click", this.toastEvent);
        this.$(".js-toast-form").on("submit", this.toastEvent);
    }

    toastEvent() {
        let $this = $(this);

        if ($this.data("js-toasting")) {
            return;
        }

        $this.data("js-toasting", true);

        if ($this.data("title") || $this.data("message")) {
            Toast.display({
                title: $this.data("title"),
                message: $this.data("message"),
                icon: $this.data("icon"),
                timeout: $this.data("timeout")
            }).catch(() => {
                $this.data("js-toasting", false);
            });
        }
    }

    static success(title, message, icon, timeout) {
        return this.display({
            title: title,
            message: message,
            icon: icon || "checkmark",
            type: "success",
            timeout: timeout === undefined ? 5000 : timeout
        });
    }

    static error(title, message, icon, timeout) {
        return this.display({
            title: title,
            message: message,
            icon: icon || "warning",
            type: "error",
            timeout: timeout === undefined ? 5000 : timeout
        });
    }

    static warning(title, message, icon, timeout) {
        return this.display({
            title: title,
            message: message,
            icon: icon || "notification",
            type: "warning",
            timeout: timeout === undefined ? 5000 : timeout
        });
    }

    static info(title, message, icon, timeout) {
        return this.display({
            title: title,
            message: message,
            icon: icon || "info",
            type: "info",
            timeout: timeout === undefined ? 5000 : timeout
        });
    }

    static display(conf) {
        const defer = $.Deferred();

        const config = {
            type: "info",
            icon: null,
            position: "topCenter",
            transitionIn: "fadeInDown",
            transitionInMobile: "fadeInDown",
        };

        $.extend(config, conf);

        config.onOpened = () => {
            defer.resolve();
        };

        config.onClosed = () => {
            defer.reject();
        };

        config.color = this.getColor(config.type);
        config.icon = "icon icon-" + config.icon;

        iziToast.show(config);

        return defer.promise();
    }

    static getColor(type) {
        switch (type) {
            case "success":
                return "green";

            case "warning":
                return "yellow";

            case "error":
                return "red";

            case "info":
                return "blue";
        }
    }
}

app.registerModule("Toast", Toast, true);